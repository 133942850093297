@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}


@keyframes slideOut {
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
    from {
        transform: translateX(0);
        opacity: 1;
    }
}
